  import React,{useEffect,useState} from "react";
  import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
  
  
  const Services8 = ({  servicesData }) => {

  const extraContentDetail=servicesData[0]?.extraContentDetail;
    
  const parsingHeadings = (extraContentDetail)=>{
    if (typeof window !== "undefined") {
      const doc = new DOMParser().parseFromString(extraContentDetail,"text/html");
    const headings=doc.querySelectorAll("h1,h2,h3,h4,h5,h6");
      return doc.body.innerHTML;
    } else{
      return extraContentDetail;
    }
  };
const [headingIds,setHeadingIds]=useState([]);

  useEffect(() => {
  
    const assignHeadingIds = () => {
      const headings = document.querySelectorAll(".extra-cont-sec h1, .extra-cont-sec h2, .extra-cont-sec h3, .extra-cont-sec h4, .extra-cont-sec h5, .extra-cont-sec h6");
  
      const ids = Array.from(headings).map((heading, index) => {
        const id = `heading-${index}`;
        heading.id = id;
        return id;
      });
  
      setHeadingIds(ids);
    };
  
    assignHeadingIds();
  }, []);
  

  if (!servicesData || servicesData.length === 0) {
    return null;
  }
  return (
    <>
    <div class="line left"></div>

    {servicesData.map((item, index) => {
      if (item?.serviceIntroHead && item?.serviceIntroDetail) {
    return(
      <>
        <section className="min-area serv-block sub-bg section-padding serv-arch wow fadeInUp">
          <div className="container">
            <div className="row flex-column service-head-row">
                    <div className="col-lg-12 col-md-12">
                      <div className="content htit">
                      <h4 className="mx-auto text-center fit-width wow color-font animated">
                        {item?.serviceIntroHead}
                      </h4>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="extra-text">
                        <p>
                        {item?.serviceIntroDetail}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
        </section>
        <div class="line right"></div>
      </>
        );
      }
      else{
        return null;
      }
  })}


    <section className=" min-area sub-bg serv-block section-padding serv-arch wow fadeInUp">
      <div className="container">
        {servicesData && servicesData?.map((value, index) => (
          <div key={index} className="row service-head-row">
              <div className="col-lg-12 col-md-12 text-center content">
                <h4 className="w-75 m-auto wow color-font animated">{value?.servicesTypesHead}</h4>
              </div>
              <div className="col-lg-12 col-md-12">
                <p>{value?.servicesTypesTagline}</p>
              </div>
              <div className="list-feat mt-40">
                <ul className="service-head-list">
                  {value?.technologiesData?.nodes &&
                    value?.technologiesData?.nodes.map((data, i) => {
                      return (
                        <li className="mb-20 col-lg-3 col-md-4 item bg-img">
                          <h6 className="numb">0{i + 1}</h6>
                          <h5>{data?.title}</h5>
                          <Link
                            to={`/services/${value?.slug}/${data?.slug}`}
                            state={{ selectedTechnology: data.slug }}
                            onClick={()=>{checkPaceClass()}}
                          >
                            Read More
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              </div>
          </div>
        ))}
      </div>
      <div className="circle-blur"></div>
      <div className="circle-blur two"></div>
    </section>

    <div class="line left"></div>
    
    {servicesData.map((value, index) => {
      if (value?.technologiesForService?.nodes?.title) {
        return(
          <>
          <section className="min-area serv-block section-padding tech serv-arch wow fadeInUp">
            <div className="container">
                <div key={index} className="row service-head-row sec-head m-0">
                  <h6 className="wow fadeIn">
                            Technologies Used
                          </h6>
                    <div className="col-lg-12 col-md-12 text-center content">
                      <h4 className="w-75 m-auto wow color-font animated">{value?.technologiesForService?.nodes?.title}</h4>
                    </div>
                    <div className="">
                      <p>{value?.technologiesForServiceTagline}</p>
                    </div>
                    <div className="list-feat mt-40 gfdgdfs">
                      <ul className="service-head-list">
                        {value?.technologiesForService?.nodes &&
                          value?.technologiesForService?.nodes.map((data, i) => {
                            return (
                              <li className="mb-20 col-lg-3 col-md-4 item bg-img">
                                <div className="tech-icon">
                                <img loading ="lazy" className="tech-img" src={value?.technologiesForService?.nodes[i]?.technologyIconForService?.mediaItemUrl} alt="" />
                                </div>
                                <h5>{data?.title}</h5>
                                <span className="icon-desc ellipsis" dangerouslySetInnerHTML={{ __html: value?.technologiesForService?.nodes[i]?.technologyDescription }}></span>
                                <Link
                                  to={`/technologies/${data?.slug}`}
                                  onClick={()=>{checkPaceClass()}}
                                >
                                  Read More
                                </Link>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                </div>
            </div>
            <div className="circle-blur"></div>
            <div className="circle-blur two"></div>
          </section>
    <div class="line right"></div>
    </>
        );
      }else{
        return null;
      } 
      })}

    {servicesData.map((item, index) => {
      if(item?.approachHead){
        return (
          <>
          <section className="serv-block section-padding serv-arch sub-bg apprch-sec min-area wow fadeInUp">
            <div className="container">
              <div className="row flex-column service-head-row">
                      <div className="col-lg-12 col-md-12">
                        <div className="htit content">
                        <h4 className="fit-width mx-auto wow color-font animated">
                          {item?.aprroachHead}
                        </h4>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="extra-text">
                          <p>
                          {item?.approachDetail}
                          </p>
                        </div>
                      </div>
              </div>
              <div className="list-feat mt-40">
                <ul className="service-head-list process-div">
                  {servicesData && servicesData?.map((item, i) => (
                      item?.processForService?.nodes?.map((process,index) => (
                        <div key={index} className="process-arrow w-auto w-25">
                          <div className="pro-icon">
                            <li key={index} className="p-0 item bg-img mr-auto">
                              <h6 className="numb"><center>{index + 1}</center></h6>
                            </li>
                            <div className="pro-content">
                              <h5 className="pro-title">{process?.processHead}</h5>
                              <p className="headProcess" key={index}>{process?.processDetail}</p>
                            </div>
                          </div>
                        </div>
                  ))
                  ))}
                </ul>
              </div>


            </div>
          </section>
        </>
        );}
        else{
          return null;
        }
      })}
    

    <div class="line left"></div>

    {servicesData.map((value, index) => {
      if(value?.caseStudiesHead){
        return(
        <>
          <section className=" min-area serv-block section-padding serv-arch case-studies-sec bg-img sub-bg call-action" style={{backgroundImage:'url(/img/patrn.svg)'}}>
            <div className="container">
                <div className="row service-head-row sec-head mb-30">
                    <h6 className="wow fadeIn">Related Projects</h6>
                      <div className="col-lg-12 col-md-12 content">
                      <h4 className="text-center m-auto wow color-font animated">{value?.caseStudiesHead}</h4>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <p>{value?.caseStudiesDetail}</p>
                    </div>
                </div>

                <div className="container">
                  <div className="row">
                    <div className="gallery full-width d-flex flex-wrap justify-content-center">
                      {value?.caseStudies?.nodes.map((node,index)=>(
                        <div className="col-lg-3 col-md-6 all ecommerce items wow fadeInUp work-section-height">
                          <div className="item-img">
                            <Link className="imago wow animated" to={`/work/${node.slug}`}>
                              <GatsbyImage image={node.featuredImage.node.localFile.childImageSharp.gatsbyImageData} alt="image" />
                            </Link>
                            <div className="item-img-overlay"></div>
                          </div>

                          <div className="cont">
                            <center>
                            <Link className="imago wow animated" to={`/work/${node.slug}`}>
                              <h6>{node.projectName}</h6>
                            </Link>
                            <p>
                              <small>
                                <i>
                                  {node.shortDescription}
                                </i>
                              </small>
                            </p>
                            </center>
                          </div>
                        </div>
                      ))}
                    </div>
                    {extraContentDetail?.map((detail)=>{
                      return(
                        <>                  
                            {<div dangerouslySetInnerHTML={{ __html:parsingHeadings(detail)}}/>}
                        </>
                      )
                    })}
                  </div>
                </div>
            </div>
            <div className="circle-blur"></div>
            <div className="circle-blur two"></div>
          </section>
          <div class="line right"></div>
          </>
          );}else{
            return null;
          }
        })}

{servicesData[0]?.faqs?.nodes.some(faq => faq.faqTitle) && (
  <>
    <section className="serv-arch app-faq section-padding sub-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="content md-mb50">
              <h2 className="mb-30 text-center">Frequently Asked Questions</h2>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="accordion" id="accordionColumn1">
              {servicesData[0]?.faqs?.nodes.map((faq, i) => {
                if (faq?.faqTitle) {
                  return (
                    <div className="card" key={i}>
                      <div className="card-header" id={`heading${i}`}>
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left"
                            type="button"
                            data-toggle="collapse"
                            data-target={`#collapse${i}`}
                            aria-expanded={i === 0}
                            aria-controls={`collapse${i}`}
                          >
                            <div className="title">
                              <h6 className="faqt">{faq?.faqTitle}</h6>
                            </div>
                          </button>
                        </h2>
                      </div>
                      <div id={`collapse${i}`} className={`collapse`} aria-labelledby={`heading${i}`} data-parent="#accordionColumn1">
                        <div className="card-body">{faq?.faqDescription}</div>
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className="line right"></div>
  </>
)}

    </>
  );
  };

  export default Services8;
