import React from "react";
import { GetHomeServiceData } from "components/ApiServices/GetHomeServiceData";
import { Link } from "gatsby";

const Services9 = (...props) => {
    const ServiceData = [props[0].servicesData]

  return (
    
    <section className="services box lficon section-padding position-re wow fadeInUp">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="  text-center">
            
              <h3 className="ind color-font">
                Our Services
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          {ServiceData &&
            ServiceData?.map((item) =>
              item.technologiesData.edges.map((val, index) => (
           
               
              
                <div
                  className="col-lg-4 wow fadeInLeft"
                  data-wow-delay={
                    index == 0
                      ? ".5s"
                      : index == 1
                      ? ".7s"
                      : index === 2
                      ? ".9s"
                      : ".5s"
                  }
                  key={index}
                >
                  <div className=" item-box no-curve" style={{height:"430px"}}>
                    <Link
                    
                    >
                      <div>
                        <span
                          className={`icon color-font ${val.node.icon}`}
                        ></span>
                      </div>
                      <div className="cont">
                        <h6>{val.node.title}</h6>
                        <p>
                          {val.node.titleSlogan.length > 200
                            ? val.node.titleSlogan.substring(0, 200) + ".."
                            : val.node.titleSlogan}
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
           
              ))
            )}
        </div>
      </div>
    </section>
  );
};

export default Services9;
