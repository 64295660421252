exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-blog-detail-index-jsx": () => import("./../../../src/pages/blog-detail/index.jsx" /* webpackChunkName: "component---src-pages-blog-detail-index-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-blog-list-blog-list-dark-jsx": () => import("./../../../src/pages/blog-list/blog-list-dark.jsx" /* webpackChunkName: "component---src-pages-blog-list-blog-list-dark-jsx" */),
  "component---src-pages-blog-list-blog-list-light-jsx": () => import("./../../../src/pages/blog-list/blog-list-light.jsx" /* webpackChunkName: "component---src-pages-blog-list-blog-list-light-jsx" */),
  "component---src-pages-career-index-jsx": () => import("./../../../src/pages/career/index.jsx" /* webpackChunkName: "component---src-pages-career-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-one-time-marketing-pack-index-jsx": () => import("./../../../src/pages/one-time-marketing-pack/index.jsx" /* webpackChunkName: "component---src-pages-one-time-marketing-pack-index-jsx" */),
  "component---src-pages-services-detail-index-jsx": () => import("./../../../src/pages/services-detail/index.jsx" /* webpackChunkName: "component---src-pages-services-detail-index-jsx" */),
  "component---src-pages-services-index-jsx": () => import("./../../../src/pages/services/index.jsx" /* webpackChunkName: "component---src-pages-services-index-jsx" */),
  "component---src-pages-tag-index-jsx": () => import("./../../../src/pages/tag/index.jsx" /* webpackChunkName: "component---src-pages-tag-index-jsx" */),
  "component---src-pages-technologies-index-jsx": () => import("./../../../src/pages/technologies/index.jsx" /* webpackChunkName: "component---src-pages-technologies-index-jsx" */),
  "component---src-pages-work-detail-index-jsx": () => import("./../../../src/pages/work-detail/index.jsx" /* webpackChunkName: "component---src-pages-work-detail-index-jsx" */),
  "component---src-pages-work-index-jsx": () => import("./../../../src/pages/work/index.jsx" /* webpackChunkName: "component---src-pages-work-index-jsx" */)
}

