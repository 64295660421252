import React, { useState } from "react";
import DarkTheme from "layouts/Dark";
import Services7 from "components/Services7/services7";
import Navbar from "components/Navbar/navbar";
import Footer from "components/Footer/footer";
import { GetServicesData } from "components/ApiServices/GetServicesData";
import SContactForm from "components/s-contact-form/s-contact-form";
import ParticalsOverlay from "components/Particals-Overlay/ParticalsOverlay";
import SEO from "components/seo/seo";
import LoadingScreen from "components/Loading-Screen";

const MobileAppServicesDark = (props) => {
  const servicesData = GetServicesData();
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  const [theme, setTheme] = useState("Dark");
  const fixedHeader = React.useRef(null);
  const MainContent = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;
    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });

    var storage = localStorage.getItem("theme");

    if (storage && storage != "") {
      let bodyEl = document.querySelector("body");
      bodyEl.classList.add("themeL");
      setTheme(storage);
    }

    setTimeout(() => {
      if (fixedHeader.current) {
        var slidHeight = fixedHeader.current.offsetHeight;
      }
      if (MainContent.current) {
        MainContent.current.style.marginTop = slidHeight + "px";
      }
    }, 1000);
  }, [fixedHeader, navbarRef]);

  const themeModeChange = (themeMode) => {
    let bodyEl = document.querySelector("body");
    if (themeMode == "Dark") {
      setTheme("themeL");
      bodyEl.classList.add("themeL");
      localStorage.setItem("theme", "themeL");
    } else {
      setTheme("Dark");
      bodyEl.classList.remove("themeL");
      localStorage.removeItem("theme");
    }
  };

  return (
    <DarkTheme mobileappstyle>
      <LoadingScreen/>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>

      <Navbar
        nr={navbarRef}
        lr={logoRef}
        theme={theme}
        themeModeChange={themeModeChange}
      />

      <header
        ref={fixedHeader}
        className="works-header fixed-slider hfixd valign"
      >
        <ParticalsOverlay />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-9 static">
              <div className="capt mt-50">
                <div className="parlx text-center">
                  <h1 className="color-font">Services</h1>
                  <p>
                    Creativity involves breaking out of expected &amp;
                    repeatable patterns in order to look at things in different
                    way than ever before.
                  </p>
                  <a
                    href="#contact"
                    className="butn bord curve mt-30 banner-cta"
                    onClick={()=>{checkPaceClass()}}
                  >
                    Let's connect
                  </a>
                </div>

                <div className="bactxt custom-font valign">
                  <span className="full-width banner-bg-large-text">
                    Services
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div ref={MainContent} className="main-content">
        <Services7 servicesData={servicesData} props={props} />
        <SContactForm />
        <Footer />
      </div>
    </DarkTheme>
  );
};

export const Head = () => {
  const liveUrl = typeof window !== "undefined" ? window.location.href : "";
  return (
    <>
      <SEO
        canonical={liveUrl}
        title="Services | Evolvan Info Solutions"
        description="Unleash the complete potential of your business through Evolvan's exceptional Digital engineering services, ranging from pioneering product development to strategic transformations"
      />

      <link
        href="https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap"
        rel="stylesheet"
      />
    </>
  );
};

export default MobileAppServicesDark;
