import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const Services7 = ({ servicesData }) => {
  return (
    <section className="serv-block section-padding">
      <div className="container">
        {servicesData &&
          servicesData?.map((value, index) => (
            <div
              kay={index}
              className={
                index % 2 === 0
                  ? `row wow fadeInUp ${index !== 0 ? "mt-80" : ""}`
                  : "row wow fadeInUp flex-row-reverse mt-80"
              }
              key={index}
            >
              <div className="col-lg-5">
                <div className="serv-img md-mb50">
                  <GatsbyImage  loading ="lazy"
                    image={value?.node?.serviceImage?.localFile?.childrenImageSharp[0].gatsbyImageData}
                    alt={value?.node?.altText}       
                  />
                </div>
              </div>
              <div
                id={value?.node?.slug}
                className="col-lg-6 offset-lg-1 valign"
              >
                <div className="content">

                  <h6 className="stit mb-30">
                    <span className="left"></span> {value?.node?.title}
                  </h6>

                  <h2 className="mb-30">{value?.node?.subTitle}</h2>
                  <p>{value?.node?.description}</p>

                  <div className="list-feat mt-40">
                    <ul>
                      {value?.node?.technologiesData &&
                        value?.node?.technologiesData?.nodes?.map((data, i) => (
                          <li key={i} className="mb-20">
                            <i className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                              >
                                <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
                              </svg>
                            </i>
                            <Link
                              to={`/services/${value?.node?.slug}/${data?.slug}`}
                              state={{ selectedTechnology: data?.node?.slug }}
                              onClick={()=>{checkPaceClass()}}
                            >
                              {data?.title}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="circle-blur"></div>
      <div className="circle-blur two"></div>
    </section>
  );
};

export default Services7;
