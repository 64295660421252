import React, { useState } from "react";
import { Link } from "gatsby";
const TruncHtml = require("trunc-html");

const Services6 = ({ serviceTechnologies }) => {
  const [techRange, setTechRange] = useState(4);

  const handleShowMore = (condition) => {
    if (condition === "show") {
      setTechRange(serviceTechnologies?.technologiesForService?.edges.length);
    } else {
      setTechRange(4);
    }
  };

  return (
    <section className="serv-arch">
      <div className="container-fluid">
        <div className="row justify-content-center">
          {serviceTechnologies &&
            serviceTechnologies?.technologiesForService?.edges
              ?.slice(0, techRange)
              .map((tech, index) => (
                <div
                  className={`col-lg-3 col-md-4 item bg-img `}
                  style={{ backgroundImage: `url(${tech.node.icon?.guid})` }}
                  key={index}
                >
                  <h6 className="numb">0{index + 1}</h6>
                  <h5>{tech?.node?.title}</h5>
                  <div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: TruncHtml(
                          tech?.node?.technologyDescription,
                          150
                        ).html,
                      }}
                    />
                  </div>
                  <Link
                    to={`/technologies/${tech?.node?.slug}`}
                    className="custom-font more main-color"
                    onClick={()=>{checkPaceClass()}}
                  >
                    Read More
                  </Link>
                </div>
              ))}
          <div
            className="col-12 d-flex justify-content-center"
            style={{ marginBottom: "5rem", marginTop: "2rem" }}
          >
            <a
              className="showMore butn bord curve mt-30 banner-cta"
              style={{ cursor: "pointer" }}
              onClick={() => handleShowMore(techRange <= 4 ? "show" : "hide")}
            >
              {techRange <= 4 ? "Show More" : "Hide"}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services6;
