import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";

const CaseStudySlider = () => {
  return (
    <>
      <div className="container case-studies-container section-padding pt-0">
        <div className="row p-0">
          <div className="col-md-12 p-0">
            <h4 class="ind color-font text-center">Case studies</h4>
            <div class="accordion px-3" id="accordionExample">
              <div class="card wow fadeInLeft">
                <div
                  class="card-header job-info flex-nowrap d-flex justify-content-between"
                  id="headingOne"
                >
                  <div
                    class="btn btn-link btn-block text-left brand-name show collapse py-3"
                    type="div"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Mindfull Eating
                  </div>
                </div>

                <div
                  id="collapseOne"
                  class="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div class="card-body p-0">
                    <div className="timeline-container">
                      <div class="timeline">
                        <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                          <div class="flip-card">
                            <div class="flip-card-inner">
                              <div class="flip-card-front">
                                <div className="brand-details">
                                  <div className="brand-img">
                                    <StaticImage
                                      src="../../images/marketing-case-studies/logos/mindful.png"
                                    />
                                  </div>
                                  <h3 style={{ position: "relative" }}>
                                    About Brand
                                  </h3>
                                </div>

                                <p>Hover to know more</p>
                              </div>
                              <div class="flip-card-back">
                                <p style={{ textAlign: "left" }}>
                                A certified lifestyle dietician and nutritionist, she specializes in mindful eating and therapeutic diets, including intermittent fasting. As a diabetic educator, she has successfully treated over 1,000 clients, helping them achieve their health goals with personalized and sustainable dietary plans.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                          <div class="timeline__event__icon ">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/challenges.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content ">
                            <h6>Challenges</h6>
                            <div class="timeline__event__description">
                              <p>On time quality content and reel creation.</p>
                              <p>Consistent posting.</p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
                          <div class="timeline__event__icon">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/target.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <h6>Goals</h6>
                            <div class="timeline__event__description">
                              <p>Increase reach and followers.</p>
                              <p>Have viral contents.</p>
                              <p>Reach right audience.</p>
                              <p>
                                Establish Unique Personal Brand that resonates
                                with the audience
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                          <div class="timeline__event__icon">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/problem-solving.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <div class="timeline__event__title">Solution</div>
                            <div class="timeline__event__description">
                              <p>
                                Establish Unique Personal Brand that resonates
                                with the audience.
                              </p>
                              <p>Content scheduling per audience engagement</p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type1">
                          <div class="timeline__event__icon">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/implement.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <div class="timeline__event__title">
                              Implementation
                            </div>
                            <div class="timeline__event__description">
                              <p>Daily reel with Thursday stories.</p>
                              <p>
                                Digital marketer, creative head and video editor
                                alignment with client.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                          <div class="timeline__event__icon">
                            <i class="lni-slim"></i>
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/results.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <div class="timeline__event__title ">Results</div>
                            <div class="timeline__event__description">
                              <p>
                                Insta follower reach to{" "}
                                <span className="color-font">14K.</span>{" "}
                              </p>
                              <p>
                                Average reel views reached to{" "}
                                <span className="color-font"> 4K.</span>
                              </p>
                              <p>More viral content reaching million views</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card wow fadeInLeft">
                <div
                  class="card-header job-info flex-nowrap d-flex justify-content-between"
                  id="headingTwo"
                >
                  <div
                    class="btn btn-link btn-block text-left brand-name show collapse py-3"
                    type="div"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    Oner
                  </div>
                </div>

                <div
                  id="collapseTwo"
                  class="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                >
                  <div class="card-body p-0">
                    <div className="timeline-container">
                      <div class="timeline">
                        <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                          <div class="flip-card">
                            <div class="flip-card-inner">
                              <div class="flip-card-front">
                                <div className="brand-details">
                                  <div
                                    className="brand-img"
                                    style={{ border: "1px solid white" }}
                                  >
                                    <StaticImage                                      
                                      src="../../images/marketing-case-studies/logos/oner.png"
                                    />
                                  </div>
                                  <h3 style={{ position: "relative" }}>
                                    About Brand
                                  </h3>
                                </div>

                                <p>Hover to know more</p>
                              </div>
                              <div class="flip-card-back">
                                <p style={{ textAlign: "left" }}>
                                ONER, an international fashion brand since 1997, embodies effortless style and authentic, easygoing living. From laid-back tailoring and handcrafted details to durable quality and sustainable materials, its philosophy shines through in every aspect.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                          <div class="timeline__event__icon ">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/challenges.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content ">
                            <h6>Challenges</h6>
                            <div class="timeline__event__description">
                              <p>On time quality content and reel creation.</p>
                              <p>Consistent posting.</p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
                          <div class="timeline__event__icon">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/target.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <h6>Goals</h6>
                            <div class="timeline__event__description">
                              <p>Increase reach and followers.</p>
                              <p>Have viral contents.</p>
                              <p>Reach right audience.</p>
                              <p>
                                Establish Unique Personal Brand that resonates
                                with the audience
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                          <div class="timeline__event__icon">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/problem-solving.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <div class="timeline__event__title">Solution</div>
                            <div class="timeline__event__description">
                              <p>
                                Establish Unique Personal Brand that resonates
                                with the audience.
                              </p>
                              <p>Content scheduling per audience engagement</p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type1">
                          <div class="timeline__event__icon">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/implement.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <div class="timeline__event__title">
                              Implementation
                            </div>
                            <div class="timeline__event__description">
                              <p>Daily reel with Thursday stories.</p>
                              <p>
                                Digital marketer, creative head and video editor
                                alignment with client.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                          <div class="timeline__event__icon">
                            <i class="lni-slim"></i>
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/results.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <div class="timeline__event__title ">Results</div>
                            <div class="timeline__event__description">
                              <p>
                                Insta follower reach to{" "}
                                <span className="color-font">14K.</span>{" "}
                              </p>
                              <p>
                                Average reel views reached to{" "}
                                <span className="color-font"> 4K.</span>
                              </p>
                              <p>More viral content reaching million views</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card wow fadeInLeft">
                <div
                  class="card-header job-info flex-nowrap d-flex justify-content-between"
                  id="headingThree"
                >
                  <div
                    class="btn btn-link btn-block text-left brand-name show collapse py-3"
                    type="div"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="true"
                    aria-controls="collapseThree"
                  >
                    Amit Bansal Consulting
                  </div>
                </div>

                <div
                  id="collapseThree"
                  class="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordionExample"
                >
                  <div class="card-body p-0">
                    <div className="timeline-container">
                      <div class="timeline">
                        <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                          <div class="flip-card">
                            <div class="flip-card-inner">
                              <div class="flip-card-front">
                                <div className="brand-details">
                                  <div className="brand-img">
                                    <StaticImage
                                      src="../../images/marketing-case-studies/logos/abc.png"
                                    />
                                  </div>
                                  <h3 style={{ position: "relative" }}>
                                    About Brand
                                  </h3>
                                </div>

                                <p>Hover to know more</p>
                              </div>
                              <div class="flip-card-back">
                                <p style={{ textAlign: "left" }}>
                                Amit Bansal, an accomplished business coach, holds an Engineering degree in Electronics and Communications and an MBA in HR. A certified trainer, he has conducted over 500 sessions, empowering thousands of business owners, entrepreneurs, and startup founders.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                          <div class="timeline__event__icon ">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/challenges.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content ">
                            <h6>Challenges</h6>
                            <div class="timeline__event__description">
                              <p>Low visibility and engagement on LinkedIn</p>
                              <p>
                                Difficulty reaching a targeted professional
                                audience
                              </p>
                              <p>
                                Building credibility as a thought leader in a
                                competitive space
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
                          <div class="timeline__event__icon">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/target.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <h6>Goals</h6>
                            <div class="timeline__event__description">
                              <p>Professional Brand Building</p>
                              <p>Targeted Audience Reach</p>
                              <p>Strong and wide network</p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                          <div class="timeline__event__icon">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/problem-solving.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <div class="timeline__event__title">Solution</div>
                            <div class="timeline__event__description">
                              <p>LinkedIn as platform</p>
                              <p>Content Strategy for Thought Leadership</p>
                              <p>Optimization for LinkedIn’s Algorithm</p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type1">
                          <div class="timeline__event__icon">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/implement.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <div class="timeline__event__title">
                              Implementation
                            </div>
                            <div class="timeline__event__description">
                              <p>
                                Post valuable business coaching content
                                regularly
                              </p>
                              <p>
                                Engage with the audience through comments and
                                articles
                              </p>
                              <p>
                                Utilize analytics to improve reach and
                                engagement
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                          <div class="timeline__event__icon">
                            <i class="lni-slim"></i>
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/results.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <div class="timeline__event__title ">Results</div>
                            <div class="timeline__event__description">
                              <p>Gained ~6,000 LinkedIn followers</p>
                              <p>Increased profile visits and network reach</p>
                              <p>Improved visibility and credibility.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card wow fadeInLeft">
                <div
                  class="card-header job-info flex-nowrap d-flex justify-content-between"
                  id="headingFour"
                >
                  <div
                    class="btn btn-link btn-block text-left brand-name show collapse py-3"
                    type="div"
                    data-toggle="collapse"
                    data-target="#collapseFour"
                    aria-expanded="true"
                    aria-controls="collapseFour"
                  >
                    ND Interiors
                  </div>
                </div>

                <div
                  id="collapseFour"
                  class="collapse"
                  aria-labelledby="headingFour"
                  data-parent="#accordionExample"
                >
                  <div class="card-body p-0">
                    <div className="timeline-container">
                      <div class="timeline">
                        <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                          <div class="flip-card">
                            <div class="flip-card-inner">
                              <div class="flip-card-front">
                                <div className="brand-details">
                                  <div className="brand-img">
                                    <StaticImage
                                      src="../../images/marketing-case-studies/logos/ND-Interiors.png"
                                    />
                                  </div>
                                  <h3 style={{ position: "relative" }}>
                                    About Brand
                                  </h3>
                                </div>
                                <p>Hover to know more</p>
                              </div>
                              <div class="flip-card-back">
                                <p style={{ textAlign: "left" }}>
                                ND Interior Design Studio crafts stylish, functional interiors tailored to each client, ensuring a seamless process and exceptional results. Their designs reflect individuality while blending creativity and practicality.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                          <div class="timeline__event__icon ">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/challenges.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content ">
                            <h6>Challenges</h6>
                            <div class="timeline__event__description">
                              <p>
                                Generating leads in a competitive premium
                                interior design market.
                              </p>
                              <p>
                                Cost-effective solution to maximize lead
                                generation within a limited budget.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
                          <div class="timeline__event__icon">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/target.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <h6>Goals</h6>
                            <div class="timeline__event__description">
                              <p>
                                Cost-effective solution to maximize lead
                                generation within a limited budget.
                              </p>
                              <p>
                                Achieve significant ROI via targeted lead
                                generation strategies.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                          <div class="timeline__event__icon">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/problem-solving.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <div class="timeline__event__title">Solution</div>
                            <div class="timeline__event__description">
                              <p>Implemented highly targeted ad campaigns.</p>
                              <p>Allocated the budget efficiently.</p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type1">
                          <div class="timeline__event__icon">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/implement.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <div class="timeline__event__title">
                              Implementation
                            </div>
                            <div class="timeline__event__description">
                              <p>
                                Defined specific demographics and interests
                                related to interior design, luxury, and home
                                renovation.
                              </p>
                              <p>Created visually appealing ads.</p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                          <div class="timeline__event__icon">
                            <i class="lni-slim"></i>
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/results.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <div class="timeline__event__title ">Results</div>
                            <div class="timeline__event__description">
                              <p>Achieved a 100x return on investment (ROI)</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card wow fadeInLeft">
                <div
                  class="card-header job-info flex-nowrap d-flex justify-content-between"
                  id="headingFive"
                >
                  <div
                    class="btn btn-link btn-block text-left brand-name show collapse py-3"
                    type="div"
                    data-toggle="collapse"
                    data-target="#collapseFive"
                    aria-expanded="true"
                    aria-controls="collapseFive"
                  >
                    Kanhiya Advertisement
                  </div>
                </div>

                <div
                  id="collapseFive"
                  class="collapse"
                  aria-labelledby="headingFive"
                  data-parent="#accordionExample"
                >
                  <div class="card-body p-0">
                    <div className="timeline-container">
                      <div class="timeline">
                        <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                          <div class="flip-card">
                            <div class="flip-card-inner">
                              <div class="flip-card-front">
                                <div className="brand-details">
                                  <div className="brand-img">
                                    <StaticImage
                                      style={{ maxWidth: "max-content" }}
                                      src="../../images/marketing-case-studies/logos/Kanhiya-Advertisement.png"
                                    />
                                  </div>
                                  <h3 style={{ position: "relative" }}>
                                    About Brand
                                  </h3>
                                </div>
                                <p>Hover to know more</p>
                              </div>
                              <div class="flip-card-back">
                                <p style={{ textAlign: "left" }}>
                                Kanhiya Advertisers, founded in 2008 in Bathinda, is a leader in outdoor media marketing. With over 300 OOH properties and exclusive rights in 14 major cities, including premium locations in Amritsar, Jalandhar, and Ludhiana, it is the largest OOH property owner in Punjab.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                          <div class="timeline__event__icon ">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/challenges.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content ">
                            <h6>Challenges</h6>
                            <div class="timeline__event__description">
                              <p>
                                To create unique, visually striking social media
                                content that would stand out from competitors
                                while maintaining their brand identity.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
                          <div class="timeline__event__icon">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/target.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <h6>Goals</h6>
                            <div class="timeline__event__description">
                              <p>
                                Create a unique and creative social media
                                presence.
                              </p>
                              <p>
                                Showcases the brand's innovative advertising.
                              </p>
                              <p>
                                Maintain brand consistency across all digital
                                platforms.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                          <div class="timeline__event__icon">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/problem-solving.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <div class="timeline__event__title">Solution</div>
                            <div class="timeline__event__description">
                              <p>
                                We proposed an Out- of-the-box concepts for
                                posts and flyers that represented Kanhiya
                                Group’s core advertising services.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type1">
                          <div class="timeline__event__icon">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/implement.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <div class="timeline__event__title">
                              Implementation
                            </div>
                            <div class="timeline__event__description">
                              <p>
                                We worked closely with them to understand their
                                brand ethos and developed unique content themes.
                              </p>
                              <p>Regular Content Rollouts.</p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                          <div class="timeline__event__icon">
                            <i class="lni-slim"></i>
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/results.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <div class="timeline__event__title ">Results</div>
                            <div class="timeline__event__description">
                              <p>
                                Resulted in a stronger social media presence
                              </p>
                              <p>
                                The content successfully highlighted the brand’s
                                innovative capabilities
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card wow fadeInLeft">
                <div
                  class="card-header job-info flex-nowrap d-flex justify-content-between"
                  id="headingSix"
                >
                  <div
                    class="btn btn-link btn-block text-left brand-name show collapse py-3"
                    type="div"
                    data-toggle="collapse"
                    data-target="#collapseSix"
                    aria-expanded="true"
                    aria-controls="collapseSix"
                  >
                    WPS
                  </div>
                </div>

                <div
                  id="collapseSix"
                  class="collapse"
                  aria-labelledby="headingSix"
                  data-parent="#accordionExample"
                >
                  <div class="card-body p-0">
                    <div className="timeline-container">
                      <div class="timeline">
                        <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                          <div class="flip-card">
                            <div class="flip-card-inner">
                              <div class="flip-card-front">
                                <div className="brand-details">
                                  <div className="brand-img">
                                    <StaticImage                                  
                                      src="../../images/marketing-case-studies/logos/WPS.png"
                                    />
                                  </div>
                                  <h3 style={{ position: "relative" }}>
                                    About Brand
                                  </h3>
                                </div>
                                <p>Hover to know more</p>
                              </div>
                              <div class="flip-card-back">
                                <p style={{ textAlign: "left" }}>
                                Waheguru Public School Education Society recognized the need for education in remote areas and established a school in a village to address it. Equipped with modern facilities and techniques, the school nurtures local talent, empowering students to compete globally.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                          <div class="timeline__event__icon ">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/challenges.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content ">
                            <h6>Challenges</h6>
                            <div class="timeline__event__description">
                              <p>
                                The school needed to enhance its online presence
                                and engagement to attract more students and
                                communicate effectively with parents.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
                          <div class="timeline__event__icon">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/target.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <h6>Goals</h6>
                            <div class="timeline__event__description">
                              <p>
                                Increase followers on social media platforms.
                              </p>
                              <p>Enhance the quality of content shared.</p>
                              <p>
                                Improve engagement with the school community.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                          <div class="timeline__event__icon">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/problem-solving.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <div class="timeline__event__title">Solution</div>
                            <div class="timeline__event__description">
                              <p>
                                Managed social media accounts with regular
                                engaging content.
                              </p>
                              <p>
                                Created a content strategy highlighting school
                                events and achievements.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type1">
                          <div class="timeline__event__icon">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/implement.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <div class="timeline__event__title">
                              Implementation
                            </div>
                            <div class="timeline__event__description">
                              <p>
                                Increased post frequency and quality on
                                platforms.
                              </p>
                              <p>
                                Engaged followers with interactive content
                                (polls, Q&A).
                              </p>
                              <p>
                                Monitored metrics to adjust strategies
                                accordingly.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                          <div class="timeline__event__icon">
                            <i class="lni-slim"></i>
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/results.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <div class="timeline__event__title ">Results</div>
                            <div class="timeline__event__description">
                              <p>Increased content quality</p>
                              <p>Enhanced engagement rates on social media</p>
                              <p>
                                Growth in followers, boosting digital presence
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card wow fadeInLeft">
                <div
                  class="card-header job-info flex-nowrap d-flex justify-content-between"
                  id="headingSeven"
                >
                  <div
                    class="btn btn-link btn-block text-left brand-name show collapse py-3"
                    type="div"
                    data-toggle="collapse"
                    data-target="#collapseSeven"
                    aria-expanded="true"
                    aria-controls="collapseSeven"
                  >
                    Aura Finance
                  </div>
                </div>

                <div
                  id="collapseSeven"
                  class="collapse"
                  aria-labelledby="headingSeven"
                  data-parent="#accordionExample"
                >
                  <div class="card-body p-0">
                    <div className="timeline-container">
                      <div class="timeline">
                        <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                          <div class="flip-card">
                            <div class="flip-card-inner">
                              <div class="flip-card-front">
                                <div className="brand-details">
                                  <div className="brand-img">
                                    <StaticImage
                                      src="../../images/marketing-case-studies/logos/Aura-Finance.png"
                                    />
                                  </div>
                                  <h3 style={{ position: "relative" }}>
                                    About Brand
                                  </h3>
                                </div>
                                <p>Hover to know more</p>
                              </div>
                              <div class="flip-card-back">
                                <p style={{ textAlign: "left" }}>
                                Aura Finance prioritize listening to understand our clients' needs and challenges. Beyond standard accounting and tax services, they offer practical solutions and valuable advice to help clients achieve their financial goals and maximize savings.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                          <div class="timeline__event__icon ">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/challenges.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content ">
                            <h6>Challenges</h6>
                            <div class="timeline__event__description">
                              <p>
                                To enhance the digital presence and search
                                engine visibility in Canada's competitive
                                financial services sector.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
                          <div class="timeline__event__icon">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/target.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <h6>Goals</h6>
                            <div class="timeline__event__description">
                              <p>
                                Boost organic traffic from specific regions.{" "}
                              </p>
                              <p>
                                Provide regular reports and track progress.{" "}
                              </p>
                              <p>
                                Ensure consistent monthly growth in SEO
                                performance.{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                          <div class="timeline__event__icon">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/problem-solving.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <div class="timeline__event__title">Solution</div>
                            <div class="timeline__event__description">
                              <p>Implemented an SEO strategy from scratch.</p>
                              <p>On-Page SEO</p>
                              <p>Off-Page SEO</p>
                              <p>Local SEO</p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type1">
                          <div class="timeline__event__icon">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/implement.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <div class="timeline__event__title">
                              Implementation
                            </div>
                            <div class="timeline__event__description">
                              <p>
                                Implemented a keyword strategy with 30 high-
                                search volume terms.
                              </p>
                              <p>Created SEO-friendly content.</p>
                              <p>
                                Analyzed keyword performance regularly to ensure
                                ongoing growth.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                          <div class="timeline__event__icon">
                            <i class="lni-slim"></i>
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/results.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <div class="timeline__event__title ">Results</div>
                            <div class="timeline__event__description">
                              <p>10+ keywords on first page.</p>
                              <p>25+ keywords on top 100.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card wow fadeInLeft">
                <div
                  class="card-header job-info flex-nowrap d-flex justify-content-between"
                  id="headingEight"
                >
                  <div
                    class="btn btn-link btn-block text-left brand-name show collapse py-3"
                    type="div"
                    data-toggle="collapse"
                    data-target="#collapseEight"
                    aria-expanded="true"
                    aria-controls="collapseEight"
                  >
                    RK Dental
                  </div>
                </div>

                <div
                  id="collapseEight"
                  class="collapse"
                  aria-labelledby="headingEight"
                  data-parent="#accordionExample"
                >
                  <div class="card-body p-0">
                    <div className="timeline-container">
                      <div class="timeline">
                        <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                          <div class="flip-card">
                            <div class="flip-card-inner">
                              <div class="flip-card-front">
                                <div className="brand-details">
                                  <div className="brand-img">
                                    <StaticImage
                                      src="../../images/marketing-case-studies/logos/RK-Dental.png"
                                    />
                                  </div>
                                  <h3 style={{ position: "relative" }}>
                                    About Brand
                                  </h3>
                                </div>
                                <p>Hover to know more</p>
                              </div>
                              <div class="flip-card-back">
                                <p style={{ textAlign: "left" }}>
                                RK Dental Clinic provides exceptional dental care, specializing in single-sitting RCTs, branded crowns, orthodontics, implants, surgical extractions, and dentures for comprehensive oral health.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                          <div class="timeline__event__icon ">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/challenges.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content ">
                            <h6>Challenges</h6>
                            <div class="timeline__event__description">
                              <p>
                                No existing brand identity or clear style guide.
                              </p>
                              <p>
                                Minimal online presence, leading to limited
                                reach beyond existing customers.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
                          <div class="timeline__event__icon">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/target.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <h6>Goals</h6>
                            <div class="timeline__event__description">
                              <p>
                                Develop a unique and consistent brand identity
                                for RK Dental Clinic.
                              </p>
                              <p>
                                Establish an initial presence on key social
                                media platforms.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                          <div class="timeline__event__icon">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/problem-solving.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <div class="timeline__event__title">Solution</div>
                            <div class="timeline__event__description">
                              <p>
                                We worked closely with RK Dental Clinic to
                                understand its core values, target audience and
                                implemented Brand Identity, Brand Guidelines,
                                Social Media Strategy
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type1">
                          <div class="timeline__event__icon">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/implement.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <div class="timeline__event__title">
                              Implementation
                            </div>
                            <div class="timeline__event__description">
                              <p>
                                We created a clean brand guidelines that
                                reflected the clinic’s expertise and
                                professionalism.
                              </p>
                              <p>
                                Accounts were set up on Facebook and Instagram.
                              </p>
                              <p>A series of initial creatives were designed</p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                          <div class="timeline__event__icon">
                            <i class="lni-slim"></i>
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/results.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <div class="timeline__event__title ">Results</div>
                            <div class="timeline__event__description">
                              <p>Established a Professional Brand</p>
                              <p>Increased Online Engagement</p>
                              <p>Consistent Online Presence.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card wow fadeInLeft">
                <div
                  class="card-header job-info flex-nowrap d-flex justify-content-between"
                  id="headingNine"
                >
                  <div
                    class="btn btn-link btn-block text-left brand-name show collapse py-3"
                    type="div"
                    data-toggle="collapse"
                    data-target="#collapseNine"
                    aria-expanded="true"
                    aria-controls="collapseNine"
                  >
                    KPL-6
                  </div>
                </div>

                <div
                  id="collapseNine"
                  class="collapse"
                  aria-labelledby="headingNine"
                  data-parent="#accordionExample"
                >
                  <div class="card-body p-0">
                    <div className="timeline-container">
                      <div class="timeline">
                        <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                          <div class="flip-card">
                            <div class="flip-card-inner">
                              <div class="flip-card-front">
                                <div className="brand-details">
                                  <div className="brand-img">
                                    <StaticImage
                                      src="../../images/marketing-case-studies/logos/KPL-6.png"
                                    />
                                  </div>
                                  <h3 style={{ position: "relative" }}>
                                    About Event
                                  </h3>
                                </div>
                                <p>Hover to know more</p>
                              </div>
                              <div class="flip-card-back">
                                <p style={{ textAlign: "left" }}>
                                KPL 6 is the sixth edition of the KVM School's annual cricket tournament, bringing together alumni and students for an exciting and competitive event. The tournament celebrates sportsmanship, camaraderie, and the spirit of cricket within the KVM community.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                          <div class="timeline__event__icon ">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/challenges.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content ">
                            <h6>Challenges</h6>
                            <div class="timeline__event__description">
                              <p>
                                The challenge was to create significant hype
                                around KPL6 through social media, ensuring
                                maximum engagement and awareness within the
                                alumni community.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
                          <div class="timeline__event__icon">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/target.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <h6>Goals</h6>
                            <div class="timeline__event__description">
                              <p>Generate excitement for KPL6.</p>
                              <p>
                                Increase event visibility through social media
                                platforms.
                              </p>
                              <p>Engage alumni and foster participation.</p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                          <div class="timeline__event__icon">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/problem-solving.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <div class="timeline__event__title">Solution</div>
                            <div class="timeline__event__description">
                              <p>
                                We executed a comprehensive social media
                                strategy.
                              </p>
                              <p>
                                Created Facebook events with engaging content
                                and creatives.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type1">
                          <div class="timeline__event__icon">
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/implement.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <div class="timeline__event__title">
                              Implementation
                            </div>
                            <div class="timeline__event__description">
                              <p>Created 27 weekly posts</p>
                              <p>Launched 5 paid brand awareness campaigns</p>
                              <p>
                                Designed 10 printable banners and 50+ resized
                                creatives{" "}
                              </p>
                              <p>
                                Documented the Player Auction event with
                                targeted photo and video content
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                          <div class="timeline__event__icon">
                            <i class="lni-slim"></i>
                            <div class="timeline__event__date">
                              <StaticImage
                                src="../../images/marketing-case-studies/icon/results.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="timeline__event__content">
                            <div class="timeline__event__title ">Results</div>
                            <div class="timeline__event__description">
                              <p>Facebook Reach: 154,380 (27.3%↑)</p>
                              <p>Instagram Reach: 664,513 (340.9% ↑)</p>
                              <p>Total Reel Plays: 32,000</p>
                              <p>Paid Reach: 1,070,283</p>
                              <p>Published Content: 325 (avg. 6.5 per day)</p>
                              <p>Cross-Posting: 16.2K Plays</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseStudySlider;
